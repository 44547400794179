// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-2-index-tsx": () => import("./../../../src/pages/2/index.tsx" /* webpackChunkName: "component---src-pages-2-index-tsx" */),
  "component---src-pages-3-index-tsx": () => import("./../../../src/pages/3/index.tsx" /* webpackChunkName: "component---src-pages-3-index-tsx" */),
  "component---src-pages-4-index-tsx": () => import("./../../../src/pages/4/index.tsx" /* webpackChunkName: "component---src-pages-4-index-tsx" */),
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-5-index-tsx": () => import("./../../../src/pages/5/index.tsx" /* webpackChunkName: "component---src-pages-5-index-tsx" */),
  "component---src-pages-6-index-tsx": () => import("./../../../src/pages/6/index.tsx" /* webpackChunkName: "component---src-pages-6-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

